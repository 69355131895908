import React from "react"
import { useWindowSize } from "react-use"
import styled from "styled-components"
import Seo from "../components/seo"
import BlogLayout from "../components/bloglayout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FiMoreHorizontal } from "react-icons/fi"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { Toc } from "../components/toc"

const BreadcrumbWrapper = styled.span`
  margin-bottom: 12px;
  display: block;
`

export default function BlogTemplate({ pageContext, location }) {
  const {
    breadcrumb: { crumbs },
    data,
  } = pageContext
  const { markdownRemark, authorsJson } = data
  const { width } = useWindowSize()
  const { frontmatter, html, timeToRead, tableOfContents } = markdownRemark
  const { author } = frontmatter
  const image = getImage(frontmatter?.image)
  const authorObj = authorsJson.authors.find(res => res.name === author)
  const authorImage = getImage(authorObj.image)
  return (
    <BlogLayout toc={tableOfContents} location={location}>
      <div className="blog-post-container" style={{ fontWeight: "500" }}>
        <div className="blog-post">
          <div style={{ maxWidth: "100%", margin: "auto" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                fontSize: "14px",
                flexWrap: "wrap",
              }}
            >
              <span>
                Posted on: <span>{frontmatter.date}</span>
              </span>
              <span
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                |
              </span>
              <span>{timeToRead} min read</span>
              <span
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                |
              </span>
              <span>{frontmatter.author}</span>
            </div>
            <h1
              style={{
                display: "flex",
                marginBottom: "30px",
                fontSize: "32px",
              }}
            >
              {frontmatter.title}
            </h1>

            <BreadcrumbWrapper>
              <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
            </BreadcrumbWrapper>
          </div>
          {image ? <GatsbyImage image={image} alt={frontmatter.title} /> : null}

          {/* {width < 860 ? (
            <div style={{ margin: "20px 0px" }}>
              <Toc />
            </div>
          ) : null} */}

          <div style={{ maxWidth: "100%", margin: "auto" }}>
            <div
              className="blog-post-content"
              style={{ marginTop: "30px" }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              <FiMoreHorizontal size="60" />
            </div>
            <div style={{ padding: "20px 0px" }}>
              <h2>About Author:</h2>
              <div
                style={{
                  paddingTop: "20px",
                  marginBottom: "20px",
                  display: "grid",
                  gridTemplateColumns: width < 700 ? "1fr" : "1fr 2fr",
                  gridAutoRows: "auto",
                  gap: "1rem",
                }}
              >
                {authorObj && (
                  <GatsbyImage
                    image={authorImage}
                    alt={authorObj.name}
                    style={{
                      height: "200px",
                      width: "200px",
                      borderRadius: "50%",
                    }}
                  />
                )}
                <div>
                  <h3>{authorObj.name}</h3>
                  <p style={{ lineHeight: "1.5" }}>{authorObj.bio}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlogLayout>
  )
}

export const Head = ({ pageContext, location }) => {
  const { data } = pageContext
  const { markdownRemark, authorsJson } = data
  const { frontmatter } = markdownRemark
  const { author, slug } = frontmatter
  const authorObj = authorsJson.authors.find(res => res.name === author)
  return (
    <Seo
      title={frontmatter.title}
      description={frontmatter.excerpt}
      twitterUsername={authorObj.twitterUsername}
      pathname={slug}
      image={frontmatter?.image?.publicURL}
      author={author}
    />
  )
}
