import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 16px;
  background-color: lavender;
  border-radius: 2px;
`
export const Toc = ({ toc }) => {
  return (
    <Wrapper>
      <p>Table of contents</p>
      {<p>{toc}</p>}
    </Wrapper>
  )
}
