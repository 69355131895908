import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowSize } from "react-use"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
import Header from "./header"
import "./layout.css"
import { SideGoogleAds } from "./googleads"

const BlogLayout = ({ children, location }) => {
  deckDeckGoHighlightElement()
  const { width } = useWindowSize()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const path = location?.pathname || ""

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {width < 860 ? (
        <main className="middle" style={{ margin: "0px 32px" }}>
          {children}
        </main>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "75% calc(25% - 2rem)",
            gridAutoRows: "auto",
            gap: "2rem",
            maxWidth: "1050px",
            padding: "0px 30px",
            margin: "auto",
            marginTop: "48px",
          }}
        >
          <main className="middle">{children}</main>
          <div
            style={{
              width: "100%",
              marginTop: "142px",
              positionWebkit: "-webkit-sticky",
              position: "sticky",
              top: "30px",
              alignSelf: "start",
            }}
          >
            {/* <SideGoogleAds path={path} />
            <SideGoogleAds path={path} /> */}
          </div>
        </div>
      )}

      <footer
        style={{
          marginTop: `var(--space-5)`,
          fontSize: `var(--font-sm)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "80px 0px",
        }}
      >
        © {new Date().getFullYear()} &middot; Copyright Letsaskhow
      </footer>
    </>
  )
}

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogLayout
